



































import {
  defineComponent,
  ref,
  computed,
  useContext,
  useRoute,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';

import {
  SfBreadcrumbs,
  SfLoader,
  SkeletonLoader
} from '~/components';

import {
  useWishlist,
  useProduct,
  useUiState,
  useDataFetch
} from '~/composables';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

import {
  useCache,
  CacheTagPrefix
} from '@vue-storefront/cache';

import { merge } from 'lodash-es';
import { getBreadcrumbs } from '~/modules/catalog/product/getters/productGetters';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { usePageStore } from '~/stores/page';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import type { Product, ProductAttribute } from '~/modules/catalog/product/types';
import getProductPriceBySkuGql from '~/modules/catalog/product/queries/getProductPriceBySku.gql';
import { UpsellProductsQuery } from '~/modules/catalog/product/queries/getUpsellData';
import { sendEcommerce } from '~/utilities/helpers';
import { useConfigStore } from '~/stores/config';

export default defineComponent({
  name: 'ProductPage',
  components: {
    LoadWhenVisible,
    SfBreadcrumbs,
    SfLoader,
    SkeletonLoader,
    SimpleProduct: () => import('~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue'),
    BundleProduct: () => import('~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue'),
    ConfigurableProduct: () => import('~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue'),
    GroupedProduct: () => import('~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue'),
    ProductSkeleton: () => import('~/modules/catalog/product/components/ProductSkeleton.vue'),
    RelatedProducts: () => import('~/modules/catalog/product/components/RelatedProducts.vue'),
    ProductRecommendations: () => import('~/modules/mindbox/components/ProductRecommendations.vue'),
    ProductSizeSidebar: () => import('~/modules/catalog/product/components/size-table/ProductSizeSidebar.vue'),
    NotifyMe: () => import('~/modules/catalog/product/components/NotifyMe.vue'),

  },
  setup() {
    const { routeData } = usePageStore();
    const { addTags } = useCache();
    const { localePath, app: { i18n } } = useContext();
    const { getProductDetails, loading } = useProduct();
    const { error: nuxtError } = useContext();
    const { load: loadWishlist } = useWishlist();
    const route = useRoute();

    const {
      loading: loadingPrice,
      loadData,
    } = useDataFetch();

    const {
      isProductSizeSidebarOpen,
      isNotifyMeSidebarOpen,
      toggleNotifyMeSidebar
    } = useUiState();

    const websiteName = computed(() => useConfigStore().storeConfig.website_name);

    const product = ref<Product | null>(null);
    const productSku = ref(routeData.sku || '');
    const skuForNotify = ref('');
    const upsellItems = ref(null);

    const breadcrumbs = computed(() => {
      const productCategories = product.value?.categories ?? [];
      const productBreadcrumbs = getBreadcrumbs(
        product.value,
        Array.isArray(productCategories) ? [...productCategories].pop() : null,
      ).map((breadcrumb) => ({ ...breadcrumb, link: decodeURIComponent(localePath(breadcrumb.link)) }));
      productBreadcrumbs.unshift({
        text: i18n.tc('Home'),
        link: localePath('/'),
      });

      return productBreadcrumbs;
    });
    const isBreadcrumbsReady = computed(() => {
      return breadcrumbs.value.length > 1 && breadcrumbs.value[1]?.text !== '';
    });
    const renderer = computed(() => product.value?.__typename ?? ProductTypeEnum.SIMPLE_PRODUCT);
    const isProductReady = computed(() => product.value && product.value.hasOwnProperty('head_description'));

    const handleNotifyMe = (sku) => {
      skuForNotify.value = sku;
      toggleNotifyMeSidebar();
    };

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(route.value.query)
        .filter((config) => config[0] !== 'wishlist')
        .map(
          (config) => config[1],
        ) as string[],
    });

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductDetails({
        ...searchQuery,
      });
      product.value = merge({}, product.value, result?.items[0] as Product ?? null);
    };

    const fetchProductExtendedData = async (searchQuery = getBaseSearchQuery()) => {

      const { data } = await loadData(getProductPriceBySkuGql, searchQuery);

      product.value = merge({}, product.value, data.products?.items?.[0] as Product);
    };

    const fetchUpsellsProducts = async () => {
      const { data } = await loadData(UpsellProductsQuery, { sku: routeData.sku });

      upsellItems.value = data.products?.items[0]?.upsell_products?.filter(product => product).length
        ? data.products.items[0].upsell_products.filter(product => product)
        : null;

      if (upsellItems.value) {
        upsellItems.value = upsellItems.value.map(item => Object.assign({}, item, { qty: 0 }));
      }
    };

    useFetch(async () => {
      await fetchProductBaseData();

      if (Boolean(product?.value?.sku) === false) nuxtError({ statusCode: 404 });

      const tags = [
        {
          prefix: CacheTagPrefix.View,
          value: `product-${routeData.sku}`,
        },
      ];

      const productTags = [{
        prefix: CacheTagPrefix.Product,
        value: product.value.uid,
      }];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      addTags([...tags, ...productTags]);
    });

    onMounted(async () => {
      await fetchProductExtendedData();
      await fetchUpsellsProducts();
      await loadWishlist();

      // Ecommerce and Pixels
      sendEcommerce('view_item', 'product', product.value,
        {
          'goods_id': product.value.sku,
          'goods_price': product.value.price_range.minimum_price.final_price.value
        }
      );
    });

    return {
      isNotifyMeSidebarOpen,
      isProductSizeSidebarOpen,
      renderer,
      loading,
      product,
      isProductReady,
      productSku,
      fetchProduct: fetchProductExtendedData,
      breadcrumbs,
      isBreadcrumbsReady,
      upsellItems,
      skuForNotify,
      handleNotifyMe,
      loadingPrice,
      websiteName
    };
  },
  head() {
    const product = this.product as Product;

    return getMetaInfo(
      product && product.media_gallery
        ? {
          ...product,
          ...{ og_type: 'product' },
          ...{ og_title: product.name as unknown as ProductAttribute },
          ...{ og_description: product.head_description as unknown as ProductAttribute },
          ...{ og_image: product.media_gallery[0].desktop as unknown as ProductAttribute },
          ...{ og_url: this.websiteName + product.url_rewrites[0].url as unknown as ProductAttribute },
        }
        : null
    );
  },
});
