export const UpsellProductsQuery = `
query productUpsell($sku: String!){
  products(filter: { sku: { eq: $sku } }) {
    items {
      upsell_products {
        name
        id
        sku
        product_thumbnail
        stock_status
        saleable_qty
        tag_line
        url_key
        url_rewrites {
          url
        }
        type_id
        uid
        __typename
        special_price
        price_range {
          minimum_price {
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
          maximum_price {
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              sku
              label
              uid
              saleable_qty
            }
          }
        }
      }
    }
  }
}
`;
