import type { MetaInfo } from 'vue-meta';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  if (!page) {
    return null;
  }

  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name) {
    seoTags.title = page?.meta_title || page?.title || page?.name;
  }
  if (page?.meta_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page.meta_description,
    });
  }
  if (page?.meta_keyword || page?.meta_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords,
    });
  }
  if (page?.og_type) {
    seoTags.meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: page?.og_type,
    });
  }
  if (page?.og_title) {
    seoTags.meta.push({
      hid: 'og:title',
      property: 'og:title',
      content: page?.og_title,
    });
  }
  if (page?.og_description) {
    seoTags.meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: page?.og_description,
    });
  }
  if (page?.og_image) {
    seoTags.meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: page?.og_image,
    });
  }
  if (page?.og_url) {
    seoTags.meta.push({
      hid: 'og:url',
      property: 'og:url',
      content: page?.og_url,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  return seoTags;
};
